import networks from "@/util/networks";
export default {
  methods: {
    getContractAddressByCurrency(currency, blockChainId) {
      return blockChainId
        ? networks.find((i) => i.symbol === currency.toUpperCase())
            ?.nftContract || ""
        : "";
    },
  },
};
