<template>
  <div class="search-results">
    <div class="search-list" v-if="tags.length">
      <div class="search-title">Tags</div>

      <div class="flex">
        <router-link
          v-for="(item, index) in tags"
          :key="index"
          :to="
            nftLink(
              item.blockChainId || item.id,
              item.currency,
              getContractAddressByCurrency(item.currency, item.blockChainId)
            )
          "
          class="search-item"
        >
          <TokenContentType
            :wrapper="'search-image'"
            :file-url="item.fileUrl"
            :token-type="item.type"
            :show-audio-player="false"
            :preview-url="item.previewUrl"
          />

          <span class="search-data">
            <span>{{ item.title }}</span>
            <Username
              :wrap-class="'username'"
              :user-address="item.creator.address"
              :username="item.creator.nickname"
            />
          </span>
        </router-link>
      </div>
    </div>

    <div class="search-list" v-if="nfts.length">
      <div class="search-title">NFTs</div>

      <div class="flex">
        <router-link
          v-for="(item, index) in nfts"
          :key="index"
          :to="
            nftLink(
              item.blockChainId || item.id,
              item.currency,
              getContractAddressByCurrency(item.currency, item.blockChainId)
            )
          "
          class="search-item"
        >
          <TokenContentType
            :wrapper="'search-image'"
            :file-url="item.fileUrl"
            :token-type="item.type"
            :show-audio-player="false"
            :preview-url="item.previewUrl"
          />

          <span class="search-data">
            <span>{{ item.title }}</span>
            <Username
              :wrap-class="'username'"
              :user-address="item.creator.address"
              :username="item.creator.nickname"
            />
          </span>
        </router-link>
      </div>
    </div>

    <div class="search-list" v-if="collections.length">
      <div class="search-title">Collections</div>

      <div class="flex">
        <router-link
          v-for="(item, index) in collections"
          :key="index"
          :to="collectionLink(item.id)"
          class="search-item"
        >
          <span class="search-image">
            <img :src="item.fileUrl" />
          </span>

          <span class="search-data">
            <span>{{ item.title }}</span>
            <Username
              :wrap-class="'username'"
              :user-address="item.owner.address"
              :username="item.owner.nickname"
            />
          </span>
        </router-link>
      </div>
    </div>

    <div class="search-list" v-if="profiles.length">
      <div class="search-title">Profiles</div>

      <div class="flex">
        <router-link
          v-for="(item, index) in profiles"
          :key="index"
          :to="profileLink(item.id)"
          class="search-item"
        >
          <span class="search-image">
            <img :src="appointAvatar(item.userInfo.avatarUrl, item.id)" />
          </span>

          <span class="search-data">
            <Username
              :wrap-class="'username'"
              :user-address="item.address"
              :username="item.nickname"
            />
          </span>
        </router-link>
      </div>
    </div>

    <NoRecordsFound
      v-if="
        !nfts.length &&
        !collections.length &&
        !profiles.length &&
        !tags.length &&
        !loading
      "
    />
  </div>
</template>

<script>
import appointAvatarMixin from "@/mixins/appointAvatarMixin";
import userInfoMixin from "@/mixins/userInfoMixin";
import TokenContentType from "@/components/common/TokenContentType";
import Username from "@/components/common/Username";
import NoRecordsFound from "@/components/common/NoRecordsFound";
import clickOutsideMixin from "@/mixins/clickOutsideMixin";
import getContractAddressByCurrencyMixin from "@/mixins/getContractAddressByCurrencyMixin";
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "SearchResults",
  components: { NoRecordsFound, TokenContentType, Username },
  mixins: [
    useLinkMixin,
    appointAvatarMixin,
    userInfoMixin,
    clickOutsideMixin,
    getContractAddressByCurrencyMixin,
    useLinkMixin,
  ],
  props: {
    loading: {
      required: true,
    },
    nfts: {
      type: Array,
    },
    collections: {
      type: Array,
    },
    profiles: {
      type: Array,
    },
    tags: {
      type: Array,
    },
  },
};
</script>
