<template>
  <span :class="wrapper">
    <template v-if="isImage">
      <img v-if="isImage" v-lazy="fileUrl" alt="token" />
    </template>
    <template v-if="isVideo">
      <video
        v-if="isVideo"
        :autoplay="autoplay"
        :controls="controls"
        :muted="mute"
        :src="fileUrl"
        loop
        playsinline
        @loadeddata="isFileLoaded = true"
      />
    </template>
    <template v-if="isAudio">
      <img
        v-if="previewUrl && !showAudioPlayer"
        v-lazy="previewUrl"
        alt="token"
      />
      <img v-if="previewUrl && artworkPage" v-lazy="previewUrl" alt="token" />
      <img
        v-if="!previewUrl && !showAudioPlayer"
        v-lazy="audioTokenPlug"
        alt="token"
      />
      <img
        v-if="!previewUrl && artworkPage"
        v-lazy="audioTokenPlug"
        alt="token"
      />
      <audio
        v-if="showAudioPlayer"
        :src="fileUrl"
        @canplaythrough="isFileLoaded = true"
        controls
      />
    </template>
    <template v-if="(isVideo && !isFileLoaded && fileUrl) || !tokenType">
      <img src="@/assets/img/preloader.svg" />
    </template>
  </span>
</template>

<script>
export default {
  props: {
    showAudioPlayer: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    wrapper: {
      type: String,
      default: () => "token-wrapper",
      required: false,
    },
    tokenType: {
      type: String,
    },
    fileUrl: {
      required: true,
    },
    previewUrl: {
      required: false,
    },
    showPlayer: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    mute: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    autoplay: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    controls: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    artworkPage: {
      type: Boolean,
      default: () => false,
      required: false,
    },
  },
  data: () => ({
    isFileLoaded: false,
    audioTokenPlug: document
      .getElementById("body")
      .classList.contains("light-theme")
      ? require("@/assets/img/audio-light.svg")
      : require("@/assets/img/audio.svg"),
  }),
  computed: {
    isImage() {
      return (
        this.tokenType?.toUpperCase() === "IMAGE" ||
        this.tokenType?.toUpperCase() === "GIF"
      );
    },
    isVideo() {
      return this.tokenType?.toUpperCase() === "VIDEO";
    },
    isAudio() {
      return this.tokenType?.toUpperCase() === "AUDIO";
    },
  },
};
</script>
