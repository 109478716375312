export default {
  data: () => ({
    isCopied: false,
  }),
  methods: {
    async doCopy(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.isCopied = true;
        setTimeout(() => {
          this.isCopied = false;
        }, 2000);
      } catch {
        this.isCopied = false;
      }
    },
  },
};
