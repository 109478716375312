<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="sprite">
    <symbol id="svg-loupe" viewBox="0 0 17 18">
      <path d="M17,16.59l-4.4-4.4c.87-1.17,1.4-2.61,1.4-4.19,0-3.87-3.13-7-7-7S0,4.13,0,8s3.13,7,7,7c1.57,0,3.02-.52,4.19-1.4l4.4,4.4,1.41-1.41Zm-10-3.59c-2.76,0-5-2.24-5-5S4.24,3,7,3s5,2.24,5,5-2.24,5-5,5Z" />
    </symbol>
    <!--[ #svg-loupe ]-->

    <symbol id="svg-close" viewBox="0 0 12 12">
      <polygon points="1.41 12 6 7.41 10.59 12 12 10.59 7.41 6 12 1.41 10.59 0 6 4.59 1.41 0 0 1.41 4.59 6 0 10.59 1.41 12" />
    </symbol>
    <!--[ #svg-close ]-->

    <symbol id="svg-dots" viewBox="0 0 12 12">
      <circle cx="2" cy="6" r="1" />
      <circle cx="6" cy="6" r="1" />
      <circle cx="10" cy="6" r="1" />
    </symbol>
    <!--[ #svg-dots ]-->

    <symbol id="svg-copy" viewBox="0 0 13 15">
      <polygon points="3 0 3 2 4 2 4 1 12 1 12 11 11 11 11 12 13 12 13 0 3 0" />
      <path d="M0,3V15H10V3ZM9,14H1V4H9Z" />
    </symbol>
    <!--[ #svg-copy ]-->

    <symbol id="svg-done" viewBox="0 0 13 10">
      <polygon points="0 5 2 3 5 6 11 0 13 2 5 10 0 5" />
    </symbol>
    <!--[ #svg-done ]-->

    <symbol id="svg-coin-eth" viewBox="0 0 10 16">
      <polygon points="5 0 0 8 5 11 10 8 5 0" fill="#807ef9" />
      <polygon points="0 9 5 16 10 9 5 12 0 9" fill="#650cfa" />
    </symbol>
    <!--[ #svg-coin-eth ]-->

    <symbol id="svg-coin-matic" viewBox="0 0 11 11">
      <polygon points="8 4.62 10 5.79 10 8.14 8 9.31 6 8.14 6 6.94 5 7.53 5 8.71 8 10.46 11 8.71 11 5.21 8 3.46 3 6.38 1 5.21 1 2.86 3 1.69 5 2.86 5 4.06 6 3.47 6 2.29 3 .54 0 2.29 0 5.79 3 7.54 8 4.62" fill="#7950dd" />
    </symbol>
    <!--[ #svg-coin-matic ]-->

    <symbol id="svg-sign-out" viewBox="0 0 18 20">
      <path d="M14.53,5.7c1.18,1.52,1.75,3.53,1.34,5.67-.52,2.74-2.72,4.96-5.46,5.49-4.48,.87-8.41-2.54-8.41-6.86,0-1.62,.55-3.11,1.47-4.29,.31-.4,.28-.96-.08-1.31h0c-.42-.42-1.13-.39-1.5,.08C.55,6.2-.18,8.44,.04,10.84c.38,4.19,3.75,7.64,7.94,8.1,5.43,.6,10.03-3.63,10.03-8.94,0-2.08-.71-4-1.9-5.53-.37-.47-1.07-.5-1.5-.08h0c-.35,.35-.39,.92-.08,1.31Z" />
      <rect x="8" width="2" height="12" rx="1" ry="1" />
    </symbol>
    <!--[ #svg-sign-out ]-->

    <symbol id="svg-blank" viewBox="0 0 16 16">
      <polygon points="13 10 13 14 2 14 2 3 6 3 6 1 0 1 0 16 15 16 15 10 13 10" />
      <polygon points="8 0 8 2 12.59 2 7.29 7.29 8.71 8.71 14 3.41 14 8 16 8 16 0 8 0" />
    </symbol>
    <!--[ #svg-blank ]-->

    <symbol id="svg-view-all" viewBox="0 0 19 11">
      <polygon points="0 4.5 0 6.5 15 6.5 12 9.5 13.5 11 19 5.5 13.5 0 12 1.5 15 4.5 0 4.5" />
    </symbol>
    <!--[ #svg-view-all ]-->

    <symbol id="svg-edit" viewBox="0 0 18 18">
      <polygon points="11 3 0 14 0 18 4 18 15 7 11 3" />
      <polygon points="12 2 14 0 18 4 16 6 12 2" />
    </symbol>
    <!--[ #svg-edit ]-->

    <symbol id="svg-delete" viewBox="0 0 14 18">
      <path d="M10,2V0H4V2H0v2H2v14H12V4h2V2h-4Zm0,14h-2V6h-2v10h-2V4h6v12Z" />
    </symbol>
    <!--[ #svg-delete ]-->

    <symbol id="svg-add" viewBox="0 0 30 30">
      <path d="M15,0C6.72,0,0,6.72,0,15s6.72,15,15,15,15-6.72,15-15S23.28,0,15,0Zm0,28c-7.18,0-13-5.82-13-13S7.82,2,15,2s13,5.82,13,13-5.82,13-13,13Z" />
      <polygon points="21 14 16 14 16 9 14 9 14 14 9 14 9 16 14 16 14 21 16 21 16 16 21 16 21 14" />
    </symbol>
    <!--[ #svg-add ]-->

    <symbol id="svg-share" viewBox="0 0 14 16">
      <path d="M9.14,10.66l-3.22-2.01c.09-.43,.09-.87,0-1.3l3.22-2.01c1.18,.93,2.89,.87,3.98-.22,1.17-1.17,1.17-3.07,0-4.24s-3.07-1.17-4.24,0c-.75,.75-1.01,1.8-.8,2.77l-3.22,2.01c-1.18-.93-2.89-.87-3.98,.22-1.17,1.17-1.17,3.07,0,4.24,1.09,1.09,2.8,1.15,3.98,.22l3.22,2.01c-.21,.97,.05,2.02,.8,2.77,1.17,1.17,3.07,1.17,4.24,0,1.17-1.17,1.17-3.07,0-4.24-1.09-1.09-2.8-1.15-3.98-.22Zm1.15-8.37c.39-.39,1.02-.39,1.41,0,.39,.39,.39,1.02,0,1.41-.39,.39-1.02,.39-1.41,0-.39-.39-.39-1.02,0-1.41ZM3.71,8.71c-.39,.39-1.02,.39-1.41,0-.39-.39-.39-1.02,0-1.41,.39-.39,1.02-.39,1.41,0,.39,.39,.39,1.02,0,1.41Zm8,5c-.39,.39-1.02,.39-1.41,0-.39-.39-.39-1.02,0-1.41,.39-.39,1.02-.39,1.41,0,.39,.39,.39,1.02,0,1.41Z" />
    </symbol>
    <!--[ #svg-share ]-->

    <symbol id="svg-telegram" viewBox="0 0 22 18">
      <path d="M1.51,7.75C7.42,5.21,11.36,3.53,13.33,2.73,18.95,.41,20.12,.01,20.88,0c.17,0,.54,.04,.79,.23,.2,.16,.26,.39,.29,.54,.03,.16,.06,.51,.03,.79-.3,3.16-1.62,10.84-2.29,14.38-.28,1.5-.84,2-1.38,2.05-1.18,.11-2.07-.77-3.21-1.5-1.78-1.15-2.79-1.87-4.52-3-2-1.3-.7-2.02,.44-3.19,.3-.31,5.48-4.96,5.58-5.38,.01-.05,.03-.25-.09-.35-.12-.1-.29-.07-.42-.04-.18,.04-3.03,1.9-8.55,5.58-.81,.55-1.54,.82-2.2,.8-.72-.02-2.11-.4-3.15-.74C.93,9.76-.08,9.55,0,8.85c.04-.36,.55-.73,1.51-1.1h0Z" />
    </symbol>
    <!--[ #svg-telegram ]-->

    <symbol id="svg-twitter" viewBox="0 0 22 18">
      <path d="M22,1l-2.97,.74c-.82-1.05-2.09-1.74-3.53-1.74-2.49,0-4.5,2-4.5,5,0,0-7,0-10-4,0,0-2,4,2,6H1s0,3,3,4H2s1,3,5,3c0,0-3,2-7,1,0,0,2,3,7,3s13-3,13-13l2-2h-2l2-2Z" />
    </symbol>
    <!--[ #svg-twitter ]-->

    <symbol id="svg-facebook" viewBox="0 0 11 22">
      <path d="M7,7v-2c0-.55,.45-1,1-1h2V0H6c-1.66,0-3,1.34-3,3V7H0v3H3v12H7V10h3l1-3H7Z" />
    </symbol>
    <!--[ #svg-facebook ]-->

    <symbol id="svg-upload" viewBox="0 0 52 33">
      <path d="M42.97,13.05c0-.18,.03-.36,.03-.55,0-6.9-5.6-12.5-12.5-12.5-4.57,0-8.55,2.46-10.73,6.11-1.09-.7-2.38-1.11-3.77-1.11-3.87,0-7,3.13-7,7,0,.36,.04,.7,.09,1.05C3.99,13.51,0,17.79,0,23c0,5.52,4.48,10,10,10H42c5.52,0,10-4.48,10-10,0-5.19-3.96-9.46-9.03-9.95Zm-.97,17.95h-13v-8h4l-7-7-7,7h4v8H10c-4.42,0-8-3.58-8-8s3.58-8,8-8h2.02c-.63-.84-1.02-1.87-1.02-3,0-2.76,2.24-5,5-5,1.91,0,3.56,1.09,4.4,2.67,1.24-4.42,5.29-7.67,10.1-7.67,5.8,0,10.5,4.7,10.5,10.5,0,.86-.12,1.7-.31,2.5h1.31c4.42,0,8,3.58,8,8s-3.58,8-8,8Z" />
    </symbol>
    <!--[ #svg-upload ]-->

    <symbol id="svg-no-records" viewBox="0 0 12 12">
      <path d="M6,0C2.69,0,0,2.69,0,6s2.69,6,6,6,6-2.69,6-6S9.31,0,6,0Zm3,8l-1,1-2-2-2,2-1-1,2-2-2-2,1-1,2,2,2-2,1,1-2,2,2,2Z" />
    </symbol>
    <!--[ #svg-no-records ]-->

    <!-- old images -->

    <symbol id="svg-file-image" viewBox="0 0 44 30">
      <path
        d="M33,4c-2.21,0-4,1.79-4,4s1.79,4,4,4,4-1.79,4-4-1.79-4-4-4Zm0,6c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Z"
      />
      <path
        d="M0,0V30H44V0H0ZM42,28H2v-8L11,11l14,14,8-8,9,9v2Zm0-5l-9-9-8,8L11,8,2,17V2H42V23Z"
      />
    </symbol>
    <!--[ #svg-file-image ]-->

    <symbol id="svg-no-data" viewBox="0 0 30 36">
      <polygon points="21 0 21 9 30 9 21 0" />
      <path
        d="M20,10V0H0V36H30V10h-10Zm0,17l-1,1-4-4-4,4-1-1,4-4-4-4,1-1,4,4,4-4,1,1-4,4,4,4Z"
      />
    </symbol>
    <!--[ #svg-no-data ]-->

    <symbol id="svg-instagram" viewBox="0 0 20 20">
      <path d="M10,20C1.4,20,0,18.6,0,10S1.4,0,10,0s10,1.4,10,10-1.4,10-10,10Zm0-18C2.52,2,2,2.52,2,10s.52,8,8,8,8-.52,8-8-.52-8-8-8Z" />
      <path d="M10,5c-2.76,0-5,2.24-5,5s2.24,5,5,5,5-2.24,5-5-2.24-5-5-5Zm0,8c-1.66,0-3-1.34-3-3s1.34-3,3-3,3,1.34,3,3-1.34,3-3,3Z" />
      <circle cx="15" cy="5" r="1" />
    </symbol>
    <!--[ #svg-instagram ]-->

    <symbol id="svg-discord" viewBox="0 0 24 18">
      <path d="M20,1c-3-1-5-1-5-1l-.59,1.19c-.77-.12-1.57-.19-2.41-.19s-1.64,.07-2.41,.19l-.59-1.19s-2,0-5,1C4,1,0,6,0,15c0,0,2,2,6,3l1.27-1.91c-.69-.29-1.32-.63-1.87-1.02l.57-.38c1.6,.81,3.71,1.31,6.04,1.31s4.44-.5,6.04-1.31l.57,.38c-.55,.4-1.18,.74-1.87,1.02l1.27,1.91c4-1,6-3,6-3,0-9-4-14-4-14ZM8,12c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Zm8,0c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Z" />
    </symbol>
    <!--[ #svg-discord ]-->

    <symbol id="svg-fullscreen-in" viewBox="0 0 9 9">
      <polygon points="0 0 0 3 1 3 1 1 3 1 3 0 0 0" />
      <polygon points="6 0 6 1 8 1 8 3 9 3 9 0 6 0" />
      <polygon points="8 6 9 6 9 9 6 9 6 8 8 8 8 6" />
      <polygon points="3 9 0 9 0 6 1 6 1 8 3 8 3 9" />
    </symbol>
    <!--[ #svg-fullscreen-in ]-->

    <symbol id="svg-fullscreen-out" viewBox="0 0 9 9">
      <polygon points="6 6 6 9 7 9 7 7 9 7 9 6 6 6" />
      <polygon points="0 6 0 7 2 7 2 9 3 9 3 6 0 6" />
      <polygon points="2 0 3 0 3 3 0 3 0 2 2 2 2 0" />
      <polygon points="9 3 6 3 6 0 7 0 7 2 9 2 9 3" />
    </symbol>
    <!--[ #svg-fullscreen-out ]-->
  </svg>
</template>

<script>
export default {
  name: "AppSprite",
};
</script>
