var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-results"},[(_vm.tags.length)?_c('div',{staticClass:"search-list"},[_c('div',{staticClass:"search-title"},[_vm._v("Tags")]),_c('div',{staticClass:"flex"},_vm._l((_vm.tags),function(item,index){return _c('router-link',{key:index,staticClass:"search-item",attrs:{"to":_vm.nftLink(
            item.blockChainId || item.id,
            item.currency,
            _vm.getContractAddressByCurrency(item.currency, item.blockChainId)
          )}},[_c('TokenContentType',{attrs:{"wrapper":'search-image',"file-url":item.fileUrl,"token-type":item.type,"show-audio-player":false,"preview-url":item.previewUrl}}),_c('span',{staticClass:"search-data"},[_c('span',[_vm._v(_vm._s(item.title))]),_c('Username',{attrs:{"wrap-class":'username',"user-address":item.creator.address,"username":item.creator.nickname}})],1)],1)}),1)]):_vm._e(),(_vm.nfts.length)?_c('div',{staticClass:"search-list"},[_c('div',{staticClass:"search-title"},[_vm._v("NFTs")]),_c('div',{staticClass:"flex"},_vm._l((_vm.nfts),function(item,index){return _c('router-link',{key:index,staticClass:"search-item",attrs:{"to":_vm.nftLink(
            item.blockChainId || item.id,
            item.currency,
            _vm.getContractAddressByCurrency(item.currency, item.blockChainId)
          )}},[_c('TokenContentType',{attrs:{"wrapper":'search-image',"file-url":item.fileUrl,"token-type":item.type,"show-audio-player":false,"preview-url":item.previewUrl}}),_c('span',{staticClass:"search-data"},[_c('span',[_vm._v(_vm._s(item.title))]),_c('Username',{attrs:{"wrap-class":'username',"user-address":item.creator.address,"username":item.creator.nickname}})],1)],1)}),1)]):_vm._e(),(_vm.collections.length)?_c('div',{staticClass:"search-list"},[_c('div',{staticClass:"search-title"},[_vm._v("Collections")]),_c('div',{staticClass:"flex"},_vm._l((_vm.collections),function(item,index){return _c('router-link',{key:index,staticClass:"search-item",attrs:{"to":_vm.collectionLink(item.id)}},[_c('span',{staticClass:"search-image"},[_c('img',{attrs:{"src":item.fileUrl}})]),_c('span',{staticClass:"search-data"},[_c('span',[_vm._v(_vm._s(item.title))]),_c('Username',{attrs:{"wrap-class":'username',"user-address":item.owner.address,"username":item.owner.nickname}})],1)])}),1)]):_vm._e(),(_vm.profiles.length)?_c('div',{staticClass:"search-list"},[_c('div',{staticClass:"search-title"},[_vm._v("Profiles")]),_c('div',{staticClass:"flex"},_vm._l((_vm.profiles),function(item,index){return _c('router-link',{key:index,staticClass:"search-item",attrs:{"to":_vm.profileLink(item.id)}},[_c('span',{staticClass:"search-image"},[_c('img',{attrs:{"src":_vm.appointAvatar(item.userInfo.avatarUrl, item.id)}})]),_c('span',{staticClass:"search-data"},[_c('Username',{attrs:{"wrap-class":'username',"user-address":item.address,"username":item.nickname}})],1)])}),1)]):_vm._e(),(
      !_vm.nfts.length &&
      !_vm.collections.length &&
      !_vm.profiles.length &&
      !_vm.tags.length &&
      !_vm.loading
    )?_c('NoRecordsFound'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }