<template>
  <header id="header">
    <router-link :to="homeLink()" class="logo">
      <img src="@/assets/img/qnft.svg" alt="QNFT" />
    </router-link>

    <div id="search" :class="{ active: searchTabVisible }">
      <div class="search-field">
        <input
          type="search"
          name="search"
          v-model="search"
          @input="makeInput"
        />

        <div class="svg loupe">
          <svg><use xlink:href="#svg-loupe" /></svg>
        </div>

        <div
          class="svg close"
          @click="
            searchTabVisible = false;
            search = '';
          "
          v-if="search"
        >
          <svg><use xlink:href="#svg-close" /></svg>
        </div>
      </div>

      <SearchResults
        @close="searchTabVisible = false"
        :loading="loading"
        v-if="searchTabVisible"
        :collections="collections"
        :nfts="nfts"
        :profiles="profiles"
        :tags="tags"
      />
    </div>

    <nav class="desktop">
      <router-link :to="collectionsLink()">Collections</router-link>
      <router-link :to="artworksLink()">Artworks</router-link>
      <router-link :to="creatorsLink()">Creators</router-link>
    </nav>

    <router-link
      :to="signInLink()"
      class="sign-in button desktop"
      v-if="!getIsAuthorized"
      >Sign In</router-link
    >
    <router-link :to="createLink()" class="create desktop" v-else>
      <span class="hover"></span>
      <span class="title">Create</span>
    </router-link>

    <div class="dropbox desktop" v-if="getIsAuthorized">
      <div class="userpic" @click="$router.push(profileLink(getId))">
        <img :src="getAvatar" />
      </div>

      <div class="dropdown">
        <div class="wrapper">
          <div class="username">{{ truncate(getNickName, 20) }}</div>

          <div class="wallet" :data="getAddress" v-if="walletConnected">
            <div class="mono">{{ getAddress | cutHash }}</div>
            <span
              class="svg"
              :class="isCopied ? 'done' : 'copy'"
              @click="doCopy(getAddress)"
            >
              <svg v-if="isCopied"><use xlink:href="#svg-done" /></svg>
              <svg v-else><use xlink:href="#svg-copy" /></svg>
            </span>
          </div>

          <div class="balance">
            <div class="item">
              <div class="svg">
                <svg>
                  <use :xlink:href="`#svg-coin-${getSymbol.toLowerCase()}`" />
                </svg>
              </div>
              <div class="amount">{{ getBalance }} {{ getSymbol }}</div>
            </div>
          </div>

          <div class="links">
            <router-link :to="profileLink(getId)">Profile</router-link>
            <router-link :to="activityLink('all')">Activity</router-link>

            <span class="sign-out" @click="logOut">
              <span class="svg">
                <svg><use xlink:href="#svg-sign-out" /></svg>
              </span>
              <span>Sign Out</span>
            </span>
          </div>
        </div>

        <div class="wrapper darkmode">
          <div class="fel">
            <label class="checkbox">
              <input
                type="checkbox"
                v-model="theme"
                @change="toggleTheme(theme)"
              />
              <span class="cell"></span>
              <span class="text">Dark Mode</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <!-- <div id="lang" class="dropbox desktop">
      <div class="lang-active">
        <span>EN</span>
      </div>

      <div class="lang-list dropdown">
        <div class="wrapper">
          <a href="">DE</a>
          <a href="">FR</a>
          <a href="">UK</a>
        </div>
      </div>
    </div> -->

    <div class="svg dots" @click="isFixed = !isFixed">
      <svg><use xlink:href="#svg-dots" /></svg>
    </div>

    <div class="mobile" :class="{ active: isFixed }">
      <div class="flex">
        <router-link :to="homeLink()" class="logo">
          <img src="@/assets/img/qnft.svg" alt="QNFT" />
        </router-link>

        <div class="dropbox">
          <!-- <div class="lang-active">
            <span>EN</span>
          </div>

          <div class="lang-list dropdown">
            <div class="wrapper">
              <a href="">DE</a>
              <a href="">FR</a>
              <a href="">UK</a>
            </div>
          </div> -->
        </div>

        <div class="svg close" @click="isFixed = !isFixed">
          <svg><use xlink:href="#svg-close" /></svg>
        </div>
      </div>

      <div class="username" v-if="getIsAuthorized">
        {{ truncate(getNickName, 20) }}
      </div>

      <div
        class="wallet"
        :data="getAddress"
        v-if="getIsAuthorized && walletConnected"
      >
        <div class="mono">{{ getAddress | cutHash }}</div>
        <span
          class="svg"
          :class="isCopied ? 'done' : 'copy'"
          @click="doCopy(getAddress)"
        >
          <svg v-if="isCopied"><use xlink:href="#svg-done" /></svg>
          <svg v-else><use xlink:href="#svg-copy" /></svg>
        </span>
      </div>

      <div class="balance" v-if="getIsAuthorized">
        {{ getBalance }} {{ getSymbol }}
      </div>

      <nav>
        <router-link :to="collectionsLink()">Collections</router-link>
        <router-link :to="artworksLink()">Artworks</router-link>
        <router-link :to="creatorsLink()">Creators</router-link>

        <router-link :to="profileLink(getId)" v-if="getIsAuthorized"
          >Profile</router-link
        >
        <router-link :to="activityLink('all')" v-if="getIsAuthorized"
          >Activity</router-link
        >
      </nav>

      <div class="fel" v-if="getIsAuthorized">
        <label class="checkbox">
          <input type="checkbox" v-model="theme" @change="toggleTheme(theme)" />
          <span class="cell"></span>
          <span class="text">Dark Mode</span>
        </label>
      </div>

      <span class="sign-out" @click="logOut" v-if="getIsAuthorized">
        <span class="svg">
          <svg><use xlink:href="#svg-sign-out" /></svg>
        </span>
        <span>Sign Out</span>
      </span>

      <router-link :to="signInLink()" class="sign-in button" v-else
        >Sign In</router-link
      >
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import cutHashMixin from "@/mixins/cutHashMixin";
import isWalletConnectedMixin from "@/mixins/isWalletConnectedMixin";
import truncateMixin from "@/mixins/truncateMixin";
import getIsAuthorizedMixin from "@/mixins/getIsAuthorizedMixin";
import doCopyMixin from "@/mixins/doCopyMixin";
import SearchResults from "@/components/common/SearchResults";
import api from "@/api/api";
import debounceMixin from "@/mixins/debounceMixin";
import setThemeMixin from "@/mixins/setThemeMixin";
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "AppHeader",
  components: { SearchResults },
  mixins: [
    cutHashMixin,
    isWalletConnectedMixin,
    truncateMixin,
    getIsAuthorizedMixin,
    doCopyMixin,
    debounceMixin,
    useLinkMixin,
    setThemeMixin,
  ],
  data: () => ({
    theme: localStorage.getItem("dark") !== "false",
    isFixed: false,
    debouncedSearch: null,
    searchTabVisible: false,
    search: "",
    collections: [],
    nfts: [],
    profiles: [],
    loading: false,
    tags: [],
  }),
  watch: {
    $route() {
      if (this.isFixed) {
        this.isFixed = false;
      }
      this.search = "";
      this.searchTabVisible = false;
    },
    getSearch(val) {
      if (!val) {
        return;
      }
      this.search = val;
      this.searchTabVisible = true;
      this.makeSearch();
    },
  },
  computed: {
    ...mapGetters({
      getSearch: "search/getSearch",
      getBalance: "wallet/getBalance",
      getSymbol: "wallet/getSymbol",
      getMultiplier: "wallet/getMultiplier",
      getAddress: "wallet/getAddress",
      getNickName: "user/getNickName",
      getAvatar: "user/getAvatar",
      getId: "user/getId",
    }),
  },
  methods: {
    ...mapActions({
      logOut: "user/logOut",
    }),
    makeInput() {
      if (this.search && this.search.length >= 3) {
        this.debouncedSearch();
        this.searchTabVisible = true;
      }
      if (!this.search || (this.search && this.search.length < 3)) {
        this.searchTabVisible = false;
      }
    },
    setConnectWalletVisible() {
      this.$emit("setConnectWalletVisible");
    },
    async makeSearch() {
      this.loading = true;
      await Promise.all([
        this.searchCreators(),
        this.searchNfts(),
        this.searchCollections(),
        this.getNFTTokenByTag(),
      ]);
      this.loading = false;
    },
    async searchCreators() {
      try {
        const response = await api.searchCreators({
          search: this.search,
          page: 0,
        });
        this.profiles = response.content;
      } catch {
        this.profiles = [];
      }
    },
    async searchNfts() {
      try {
        const response = await api.searchNfts({
          search: this.search,
          page: 0,
        });
        this.nfts = response.content;
      } catch (e) {
        this.nfts = [];
      }
    },
    async searchCollections() {
      try {
        const response = await api.searchCollections({
          search: this.search,
          page: 0,
        });
        this.collections = response.content;
      } catch {
        this.collections = [];
      }
    },
    async getNFTTokenByTag() {
      try {
        const response = await api.getNFTTokenByTag({
          tag: this.search,
          page: 0,
        });
        this.tags = response.content;
      } catch {
        this.tags = [];
      }
    },
  },
  mounted() {
    this.debouncedSearch = this.debounce(this.makeSearch, 10);
  },
};
</script>
